import { composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

export const focusRing = tv({
  variants: {
    isFocusVisible: {
      true: "outline outline-2 outline-blue-500",
    },
  },
});

export const inputBase = tv({
  extend: focusRing,
  base: "text-base text-zinc-950 placeholder:text-zinc-400 bg-white shadow-sm border rounded-lg",
});

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string
): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}
